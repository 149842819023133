import * as THREE from 'three';

// シーン、カメラ、レンダラーの設定
const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

// 基本的な地面を作成
const groundGeometry = new THREE.PlaneGeometry(100, 100);
const groundMaterial = new THREE.MeshBasicMaterial({
  color: 0x90ee90,
  side: THREE.DoubleSide,
});
const ground = new THREE.Mesh(groundGeometry, groundMaterial);
ground.rotation.x = Math.PI / 2;
scene.add(ground);

// 建物を追加
const buildingGeometry = new THREE.BoxGeometry(5, 14, 5);
const buildingMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
building.position.y = 5;
building.position.z = -10;
scene.add(building);

// 光源を追加
const light = new THREE.PointLight(0xffffff, 1, 100);
light.position.set(0, 20, 0);
scene.add(light);

// カメラの位置を設定
camera.position.x = 0;
camera.position.y = 5;
camera.position.z = 30;

// アニメーションループ
function animate() {
  requestAnimationFrame(animate);
  renderer.render(scene, camera);
}
animate();

// ウィンドウリサイズ対応
window.addEventListener('resize', () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});
